export const GOOGLE_LOGIN = "GOOGLE_LOGIN";
export const FACEBOOK_LOGIN = "FACEBOOK_LOGIN";
export const CIVIC_LOGIN = "CIVIC_LOGIN";
export const GOOGLE_SIGNUP = "GOOGLE_SIGNUP";
export const FACEBOOK_SIGNUP = "FACEBOOK_SIGNUP";
export const CIVIC_SIGNUP = "CIVIC_SIGNUP";

export const GET_ORG = "GET_ORG";
export const ORG_SAVED = "ORG_SAVED";

export const GET_PERMISSION = "GET_PERMISSION";
export const PERMISSION_SAVED = "PERMISSION_SAVED";

export const GET_ROLE = "GET_ROLE";
export const GET_USER = "GET_USER";

export const GET_PATH = "GET_PATH";
export const VERIFY_LOCAL_USER = "VERIFY_LOCAL_USER";

export const FETCH_LEARNING_PATH_DATA_START = "FETCH_LEARNING_PATH_DATA_START";
export const FETCH_LEARNING_PATH_DATA_FAIL = "FETCH_LEARNING_PATH_DATA_FAIL";
export const FETCH_LEARNING_PATH_DATA_SUCCESS = "FETCH_LEARNING_PATH_DATA_SUCCESS";

export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT_SUCCESS = "AUTH_LOGOUT_SUCCESS";
export const AUTH_LOGOUT_FAIL = "AUTH_LOGOUT_FAIL";
export const FETCH_USER_DETAIL_SUCCESS = "FETCH_USER_DETAIL_SUCCESS";

export const CREATE_ORGANIZATION_START = "CREATE_ORGANIZATION_START";
export const CREATE_ORGANIZATION_FAIL = "CREATE_ORGANIZATION_FAIL";
export const CREATE_ORGANIZATION_SUCCESS = "CREATE_ORGANIZATION_SUCCESS";

export const UPDATE_ADVANCED_SEARCH_STATE = "UPDATE_ADVANCED_SEARCH_STATE";
export const SEARCH_RESULT = "SEARCH_RESULT";
export const SET_TEXT_INSIDE_SEARCH_BAR = "SET_TEXT_INSIDE_SEARCH_BAR";
export const SET_TEXT_INSIDE_SEARCH_BAR_NEW = "SET_TEXT_INSIDE_SEARCH_BAR_NEW";
export const UPDATE_ADVANCED_SEARCH_STATE_NEW = "UPDATE_ADVANCED_SEARCH_STATE_NEW";

export const SET_ALLHIGHLIGHTS = "SET_ALLHIGHLIGHTS";
export const SET_TAGS = "SET_TAGS";
export const SET_ZERO_HIGHLIGHTS_PRESENT = "SET_ZERO_HIGHLIGHTS_PRESENT";

export const UPDATE_HIGHTLIGHTS_SUCCESS = "UPDATE_HIGHTLIGHTS_SUCCESS";
export const UPDATE_HIGHTLIGHTS_FAIL = "UPDATE_HIGHTLIGHTS_FAIL";

export const UPDATE_TAGS_SUCCESS = "UPDATE_TAGS_SUCCESS";

export const FETCH_USER_PROFILE_SUCCESS = "FETCH_USER_PROFILE_SUCCESS";

export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";

export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const GET_ISSUES = "GET_ISSUES";
export const ISSUE_ERROR = "ISSUE_ERROR";
export const DELETE_ISSUE = "DELETE_ISSUE";
export const ADD_ISSUE = "ADD_ISSUE";
export const GET_ISSUE = "GET_ISSUE";
export const UPDATE_LIKES = "UPDATE_LIKES";
export const ADD_COMMENT = "ADD_COMMENT";
export const REMOVE_COMMENT = "REMOVE_COMMENT";
