import * as actionTypes from "./actionTypes";
import axios from "../../config/axios";

export const fetchLearningPathDataSuccess = (learningPathData) => {
  return {
    type: actionTypes.FETCH_LEARNING_PATH_DATA_SUCCESS,
    learningPathData: learningPathData,
  };
};

export const fetchLearningPathDataFail = (error) => {
  return {
    type: actionTypes.FETCH_LEARNING_PATH_DATA_FAIL,
    error: error,
  };
};

export const fetchLearningPathDataStart = () => {
  return {
    type: actionTypes.FETCH_LEARNING_PATH_DATA_START,
  };
};

export const fetchLearningPathDataById = (id) => {
  return (dispatch) => {
    dispatch(fetchLearningPathDataStart());
    axios
      .get("/learning-paths/" + id)
      .then((res) => {
        if (res.data.data[0].poniterList.length > 0) {
          localStorage.setItem("learningPathData", JSON.stringify(res.data.data[0].poniterList));
          dispatch(fetchLearningPathDataSuccess(res.data.data[0].poniterList));
        }
      })
      .catch((err) => {
        dispatch(fetchLearningPathDataFail(err));
      });
  };
};
