import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  // tags_details: "",
  // url: "",
  // text: "",
  // added_note: "",
  inNote: "",
  inText: "",
  inUrl: "",
  inTag: "",
  totalSearchResult: [],
  totalHighlightCount: "",
  fromDate: "",
  toDate: "",
  showClearSearch: false,
  searchText: "",
};

const changeAdvanceSearchState = (state, action) => {
  return updateObject(state, {
    inTag: action.items.in_tag,
    inUrl: action.items.in_url,
    inText: action.items.in_text, // ((action.items).hasOwnProperty("in_text")) ? action.items.in_text : action.items.text,
    inNote: action.items.in_note,
    showClearSearch: action.items.showClearSearch,
    fromDate: action.items.fromDate,
    toDate: action.items.toDate,
  });
};

const searchResult = (state, action) => {
  return updateObject(state, {
    totalSearchResult:
      action.from > 0 ? state.totalSearchResult.concat(action.totalSearchResult) : action.totalSearchResult,
    totalHighlightCount: action.totalHighlightCount,
  });
};

const setTextInsideSearchBar = (state, action) => {
  return updateObject(state, {
    searchText: action.searchText,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_ADVANCED_SEARCH_STATE:
      return changeAdvanceSearchState(state, action);
    case actionTypes.UPDATE_ADVANCED_SEARCH_STATE_NEW:
      return {
        ...state,
        inTag: action.payload.in_tag,
        inUrl: action.payload.in_url,
        inText: action.payload.in_text, // ((action.payload).hasOwnProperty("in_text")) ? action.payload.in_text : action.payload.text,
        inNote: action.payload.in_note,
        showClearSearch: action.payload.showClearSearch,
        fromDate: action.payload.fromDate,
        toDate: action.payload.toDate,
      };
    case actionTypes.SEARCH_RESULT:
      return searchResult(state, action);
    case actionTypes.SET_TEXT_INSIDE_SEARCH_BAR:
      return setTextInsideSearchBar(state, action);
    case actionTypes.SET_TEXT_INSIDE_SEARCH_BAR_NEW:
      return {
        ...state,
        searchText: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
