import React, { Component } from "react";
import { version } from "../../../package.json";
import PropTypes from "prop-types";
import Drift from "react-driftjs";
import config from "../../config/config";

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultFooter extends Component {
  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <div>
        {/* <span><a href="#/">Learning Paths</a> &copy; 2018 valueLabs.</span> */}
        <div className="pull-left">
          <span>
            <a> Build Version {version}</a>&nbsp;|&nbsp;
          </span>
          <span>
            <a href="https://chrome.google.com/webstore/detail/highlights-by-learning-pa/fiajhjomgpnlefcbdhfghnbhpillkklb">
              {" "}
              Chrome Extension{" "}
            </a>
            &nbsp;|&nbsp;
          </span>
          <span>
            <a href="https://play.google.com/store/apps/details?id=com.app.learningpaths.highlights"> Android App </a>
            &nbsp;|&nbsp;
          </span>
          <span>
            <a href="https://learningpaths.io/how-to-use-after-installing-highlights-extension-and-loggin-in/">
              {" "}
              How to use{" "}
            </a>
            &nbsp;|&nbsp;
          </span>
          {config.drift ? <Drift appId={config.drift.appId} /> : null}
        </div>
        <div className="pull-right">
          <span>
            <a href="https://brave.com/lea456" target="_blank">
              {" "}
              Brave Verified Creator
            </a>
            &nbsp;|&nbsp;
          </span>
          <span>
            <a href="https://m.do.co/c/f2c1c5abf4a0" target="_blank">
              {" "}
              Hosted on DO
            </a>
          </span>
        </div>
        <div className="clearfix"></div>
      </div>
    );
  }
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export default DefaultFooter;
