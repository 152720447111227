import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";
import _ from "lodash";

const initialState = {
  allHighlights: [],
  suggestionsTags: [],
  tags: [],
  noHighlightsPresent: false,
  updateHighlightsSuccess: "",
};

const setAllHighlights = (state, action) => {
  return updateObject(state, {
    allHighlights:
      action.typeOperation === "loadmore" ? state.allHighlights.concat(action.allHighlights) : action.allHighlights,
    noHighlightsPresent: _.isEmpty(action.allHighlights) ? true : false,
  });
};

const setTags = (state, action) => {
  return updateObject(state, {
    suggestionsTags: action.suggestionsTags,
    tags: action.tags,
  });
};

const updateHighlightsSuccess = (state, action) => {
  return updateObject(state, {
    updateHighlightsSuccess: action.updateHighlightSuccess,
  });
};

const updateTagsSuccess = (state, action) => {
  return updateObject(state, {
    updateTagsSuccess: action.updateTagsSuccess,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ALLHIGHLIGHTS:
      return setAllHighlights(state, action);
    case actionTypes.SET_TAGS:
      return setTags(state, action);
    case actionTypes.UPDATE_HIGHTLIGHTS_SUCCESS:
      return updateHighlightsSuccess(state, action);
    case actionTypes.UPDATE_TAGS_SUCCESS:
      return updateTagsSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
