import * as actionTypes from "./actionTypes";
import axios from "../../config/axios";

export const changeAdvanceSearchState = (item) => {
  return {
    type: actionTypes.UPDATE_ADVANCED_SEARCH_STATE,
    items: item,
  };
};

export const searchResult = (data) => {
  return {
    type: actionTypes.SEARCH_RESULT,
    totalSearchResult: data.hits,
    totalHighlightCount: data.total,
    from: data.from,
  };
};

export const setTextInsideSearchBar = (searchText) => {
  return {
    type: actionTypes.SET_TEXT_INSIDE_SEARCH_BAR,
    searchText: searchText,
  };
};

export const fetchHighlightsFromElasticSearch = (params) => {
  return (dispatch) => {
    return axios
      .get("/es/highlights/text-search", { params })
      .then((result) => {
        if (result && result.data && result.data.data) {
          dispatch(searchResult(result.data.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
