// import prodConfig from '../env_configs/prod_config.json';
// import stagingConfig from '../env_configs/staging_config.json';
import config from "./config.js";
import axios from "axios";

const instance = axios.create({
  baseURL: config.base_url,
});

//Here we are getting jwt token from local storage for every request.
//Reason: To get the latest token from local storage.
instance.interceptors.request.use((config) => {
  var token = localStorage.getItem("lpWebToken");
  var lpRefreshTone = localStorage.getItem("lpRefreshWebToken");
  if (token && config.headers && config.headers.common) {
    config.headers.common["Authorization"] = "bearer " + token;
  }

  if (lpRefreshTone && config.headers) {
    config.headers["x-refresh-token"] = lpRefreshTone;
  }

  return config;
});

instance.interceptors.response.use((response) => {
  if (response.headers && response.headers["x-token"]) {
    localStorage.setItem("lpWebToken", response.headers["x-token"]);
  }

  return response;
});

export default instance;
