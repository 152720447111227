import ColorsCode from "../utils/colors_code";
import _ from "lodash";

export function getColorCode() {
  return ColorsCode[Math.floor(Math.random() * ColorsCode.length)];
}

export function formatTags(highLight) {
  if (!Array.isArray(highLight)) return;
  return highLight.forEach((highLight) => {
    if (highLight.tags_details) {
      var tagData = [];
      var tagsName = [];
      highLight.tags_details.forEach((tags) => {
        if (tags && !tags.is_tag_deleted) {
          tagData.push({
            _id: tags._id,
            tag: tags.name,
            id: tags._id,
            text: tags.name,
          });
          tagsName.push(tags.name);
        }
      });
      highLight.tags = tagData;
      highLight.tagsName = tagsName;
    }
  });
}

// sort highlights by Date
export function orderByDateHighLights(highlights) {
  let dateAndHighlightMapper = {};
  var sortedHighlights = _.orderBy(highlights, ["created_at"], ["desc"]);
  sortedHighlights.forEach((highlight) => {
    if (highlight.created_at) {
      var tempDate = highlight.created_at.split("T")[0];
      highlight.created_date = tempDate;
      dateAndHighlightMapper[tempDate] = dateAndHighlightMapper[tempDate] || [];
      dateAndHighlightMapper[tempDate].push(highlight);
    }
  });
  return dateAndHighlightMapper;
}

// display the highlights by relevancy
export function orderByRelevancy(searchResult) {
  let dateAndHighlightMapper = {};
  searchResult.forEach((highlight) => {
    if (highlight._id) {
      let id = highlight._id;
      dateAndHighlightMapper["id"] = dateAndHighlightMapper["id"] || [];
      dateAndHighlightMapper["id"].push(highlight);
    }
  });
  return dateAndHighlightMapper;
}

export function formatElasticData(data) {
  if (data && Array.isArray(data)) {
    return data.map((highLight) => {
      if (highLight._source) {
        highLight._source._id = highLight._id ? highLight._id : "";
        highLight._source.tags = highLight._source.tags_details ? highLight._source.tags_details : [];
        highLight._source.score = highLight._score;
        highLight._source.tags.map((tag) => {
          if (tag._id) {
            tag.id = tag._id;
          }
          if (tag.name) {
            tag.text = tag.name;
          }
          return tag;
        });
        return highLight._source;
      } else {
        return highLight;
      }
    });
  } else {
    return [];
  }
}

export function showSuccessMsg(options, showSuccessMsg) {
  if (options.action === "saveNote" && showSuccessMsg) {
    document.getElementById("showSuccessMsg").innerHTML = "Saving...";
    document.getElementById("showSuccessMsg").style.width = "66px";
    document.getElementById("showSuccessMsg").style.backgroundColor = "#07bc0c";
    setTimeout(function () {
      document.getElementById("showSuccessMsg").innerHTML = "Save";
      document.getElementById("showSuccessMsg").style.width = "50px";
      document.getElementById("showSuccessMsg").style.backgroundColor = "#2a74ff";
    }, 3000);
  }
}

export function ucfirst(string = "") {
  if (string == null) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function checkEsUrlSearchParam() {
  const queryString = window.location.href;
  var res = queryString.split(":");
  const urlParams = new URLSearchParams(queryString);
  if (
    window.location.href.indexOf("fromdate") > 0 ||
    window.location.href.indexOf("todate") > 0 ||
    window.location.href.indexOf("inurl") > 0 ||
    window.location.href.indexOf("inhighlight") > 0 ||
    window.location.href.indexOf("intag") > 0 ||
    window.location.href.indexOf("intag") > 0
  ) {
    return true;
  }
  return false;
}
