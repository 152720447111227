import {
  GET_ISSUES,
  ISSUE_ERROR,
  DELETE_ISSUE,
  GET_ISSUE,
  ADD_ISSUE,
  UPDATE_LIKES,
  ADD_COMMENT,
  REMOVE_COMMENT,
} from "../actions/actionTypes";

const initialState = {
  issues: [],
  issue: null,
  loading: true,
  error: {},
};

export default function issueReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ISSUES:
      return {
        ...state,
        issues: payload,
        loading: false,
      };
    case GET_ISSUE:
      return {
        ...state,
        issues: payload,
        loading: false,
      };
    case ADD_ISSUE:
      return {
        ...state,
        data: [payload, ...state.issues.data],
        loading: false,
      };
    case DELETE_ISSUE:
      return {
        ...state,
        issues: state.issues.data.filter((issue) => issue._id !== payload),
        loading: false,
      };
    case UPDATE_LIKES:
      return {
        ...state,
        issues: state.issues.data.map((issue) =>
          issue._id === payload.id ? { ...issue, likes: payload.likes } : issue
        ),
        loading: false,
      };
    case ADD_COMMENT:
      return {
        ...state,
        issue: { ...state.issue, comments: payload },
        loading: false,
      };
    case REMOVE_COMMENT:
      return {
        ...state,
        issue: {
          ...state.issue,
          comments: state.issue.comments.filter(
            (comment) => comment._id !== payload
          ),
        },
        loading: false,
      };
    case ISSUE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
