import axios from "../../config/axios";
import * as actionTypes from "./actionTypes";

export const setAllHighlights = (result, source) => {
  return {
    type: actionTypes.SET_ALLHIGHLIGHTS,
    allHighlights: result,
    typeOperation: source,
  };
};

const setTags = (result) => {
  return {
    type: actionTypes.SET_TAGS,
    suggestionsTags: formatTags(result),
    tags: result,
  };
};

const formatTags = (tags) => {
  tags = tags.map(function (tag) {
    tag.text = tag.name;
    tag.id = tag._id;
    return tag;
  });

  return tags;
};

export const fetchAllHighlights = (params, source) => {
  return (dispatch) => {
    return axios
      .get("/highlights", { params })
      .then((result) => {
        // result.data.data.map(hightLight => {
        //     hightLight.tagsName = hightLight.tags.map(tag => {
        //         return tag.text;             // move to backend
        //     });
        //     hightLight.tagsName.join();
        // });
        if (result && result.data && result.data.data) {
          dispatch(setAllHighlights(result.data.data, source));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const fetchAllTags = () => {
  return (dispatch) => {
    return axios
      .get("/tags")
      .then((result) => {
        if (result && result.data && result.data.data) {
          dispatch(setTags(result.data.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

const updateHighlightsSuccess = (success) => {
  return {
    type: actionTypes.UPDATE_HIGHTLIGHTS_SUCCESS,
    updateHighlightSuccess: success,
  };
};

// const updateHighlightsFail = (fail) => {
//     return {
//         type: actionTypes.UPDATE_HIGHTLIGHTS_FAIL,
//         updateHIghlightFail: fail
//     }
// }

export const updateHighLight = (id, data) => {
  return (dispatch) => {
    return axios
      .put("/highlights/" + id, data)
      .then((result) => {
        if (result && result.data && result.data.data) {
          dispatch(updateHighlightsSuccess(result.data.message));
        }
      })
      .catch((error) => {
        // dispatch(updateHighlightsFail())
        console.log(error);
      });
  };
};

export const updateTags = (success) => {
  return {
    type: actionTypes.UPDATE_TAGS_SUCCESS,
    updateTagsSuccess: success,
  };
};

export const saveTag = (tag, highlight) => {
  return (dispatch) => {
    return axios
      .post("/tags", { tags: tag.text, highLight_id: highlight._id })
      .then((result) => {
        if (result && result.data && result.data.message) {
          dispatch(updateTags(result.data.message));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};
