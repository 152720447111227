import { toast } from "react-toastify";
import axios from "../../config/axios";
import * as actionTypes from "./actionTypes";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (token) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const logoutSuccess = () => {
  localStorage.clear();
  localStorage.setItem("lpUserLoginStatus", "logout");
  return {
    type: actionTypes.AUTH_LOGOUT_SUCCESS,
  };
};

export const logoutFail = () => {
  return {
    type: actionTypes.AUTH_LOGOUT_FAIL,
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logoutSuccess());
    }, expirationTime * 1000);
  };
};

export const fetchUserDetailSuccess = (userObj, maitreReferralCode) => {
  return {
    type: actionTypes.FETCH_USER_DETAIL_SUCCESS,
    first_name: userObj.first_name,
    last_name: userObj.last_name,
    email: userObj.email,
    user_id: userObj._id,
    user_obj: userObj,
    isUserLoggedIn: true,
    maitre_referral_code: maitreReferralCode,
  };
};

export const fetchUserDetail = (token, maitreReferralCode) => {
  return (dispatch) => {
    axios
      .get(`/me`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        if (response && response.data && response.data.data) {
          if (response.data.data.first_name) {
            localStorage.setItem("first_name", response.data.data.first_name);
          }
          if (response.data.data.last_name) {
            localStorage.setItem("last_name", response.data.data.last_name);
          }
          if (maitreReferralCode) {
            localStorage.setItem("maitre_referral_code", maitreReferralCode);
          }
          localStorage.setItem("user_id", response.data.data._id);
          localStorage.setItem("email", response.data.data.email);
          localStorage.setItem("user_obj", JSON.stringify(response.data.data));
          dispatch(fetchUserDetailSuccess(response.data.data, maitreReferralCode));
          localStorage.setItem("lpUserLoginStatus", "login");
        }
      });
  };
};

export const auth = (user) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .post("/signin", user)
      .then((response) => {
        if (Object.keys(response.data.data).length > 0) {
          localStorage.setItem("lpWebToken", response.data.data.token);
          localStorage.setItem("lpRefreshWebToken", response.data.data.refresh_token);
          localStorage.setItem("lpUserLoginStatus", "login");
          dispatch(authSuccess(response.data.data.token));
          dispatch(fetchUserDetail(response.data.data.token, user.maitre_referral_code));
        }
      })
      .catch((err) => {
        dispatch(authFail(err));
      });
  };
};

//TODO: before implementation needs to checked
//TODO: Read more about OAuth2, refresh tokens. When
// token is invalidated by server, get new token or logout. Takecare by Anupam.
export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem("lpWebToken");
    if (!token) {
      dispatch(logoutSuccess());
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      if (expirationDate <= new Date()) {
        dispatch(logoutSuccess());
      } else {
        // const userData = localStorage.getItem('userData');
        dispatch(authSuccess(token));
        dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));
      }
    }
  };
};

export const logout = () => {
  return (dispatch) => {
    axios
      .post("/signout")
      .then((response) => {
        if (response && response.data) {
          dispatch(logoutSuccess());
        }
      })
      .catch((error) => {
        if (error.response && error.response.status && error.response.status == 401) {
          dispatch(logoutSuccess());
        } else {
          dispatch(logoutFail());
        }
      });
  };
};

export const fetchUserProfileSuccess = (userProfileObj) => {
  return {
    type: actionTypes.FETCH_USER_PROFILE_SUCCESS,
    user_profile: userProfileObj,
  };
};

export const fetchUserProfile = (token) => {
  return (dispatch) => {
    axios
      .get(`/users/user-profile`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        if (response && response.data && response.data.data) {
          dispatch(fetchUserProfileSuccess(response.data.data));
        }
      });
  };
};

export const updateUserProfile = (token, user_profile) => {
  return (dispatch) => {
    axios
      .put(`/users/users/profile`, user_profile, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        if (response && response.data && response.data.data) {
          toast.success("Preferences Updated successfully!!!");
          console.log("Preferences Updated!!!");
        }
      });
  };
};
