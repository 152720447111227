import React, { Component } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Sentry from "@sentry/browser";
import { Button } from "reactstrap";
import "./ErrorBoundary.css";

class ErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  error = () => {
    return toast.error("Something went wrong.");
  };

  componentDidCatch = async (error, info) => {
    await this.setState({ hasError: true });
    Sentry.withScope((scope) => {
      Object.keys(info).forEach((key) => {
        scope.setExtra(key, info[key]);
      });
      Sentry.captureException(error);
    });
    if (this.state.hasError) {
      this.error();
    }
  };

  render() {
    if (this.state.hasError) {
      const containerStyle = {
        zIndex: 1000,
      };
      return (
        <React.Fragment>
          <Button className="report-feedback" onClick={() => Sentry.showReportDialog()}>
            Report feedback
          </Button>
        </React.Fragment>
      );
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
