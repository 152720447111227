import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  orgData: null,
  error: null,
  loading: false,
};

const createOrganizationDataStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const createOrganizationDataSuccess = (state, action) => {
  return updateObject(state, {
    orgData: action.organizationData,
    error: null,
    loading: false,
  });
};

const createOrganizationDataFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_ORGANIZATION_START:
      return createOrganizationDataStart(state, action);
    case actionTypes.CREATE_ORGANIZATION_SUCCESS:
      return createOrganizationDataSuccess(state, action);
    case actionTypes.CREATE_ORGANIZATION_FAIL:
      return createOrganizationDataFail(state, action);
    default:
      return state;
  }
};

export default reducer;
