import React from "react";
import Loadable from "react-loadable";
import Throbber from "./components/Throbber/Throbber";
import DefaultLayout from "./containers/DefaultLayout";

function Loading() {
  return <Throbber></Throbber>;
}

const Dashboard = Loadable({
  loader: () => import("./views/Dashboard"),
  loading: Loading,
});

const Register = Loadable({
  loader: () => import("./views/Pages/Register"),
  loading: Loading,
});

const LearningPath = Loadable({
  loader: () => import("./views/LearningPath"),
  loading: Loading,
});

const LearningPaths = Loadable({
  loader: () => import("./views/LearningPaths"),
  loading: Loading,
});

const LearningPointer = Loadable({
  loader: () => import("./views/LearningPointer"),
  loading: Loading,
});

const LearningPointerDisplay = Loadable({
  loader: () => import("./views/LearningPointerDisplay"),
  loading: Loading,
});

const Logout = Loadable({
  loader: () => import("./views/Logout"),
  loading: Loading,
});

const Organization = Loadable({
  loader: () => import("./views/Organization"),
  loading: Loading,
});

const Questionnaire = Loadable({
  loader: () => import("./views/Questionnaire"),
  loading: Loading,
});

const createLearningPath = Loadable({
  loader: () => import("./views/Scrape"),
  loading: Loading,
});

const VerifyUser = Loadable({
  loader: () => import("./views/VerifyUser"),
  loading: Loading,
});

const Highlights = Loadable({
  loader: () => import("./views/Highlights"),
  loading: Loading,
});

const Highlight = Loadable({
  loader: () => import("./views/Highlight"),
  loading: Loading,
});

const RedirectHandler = Loadable({
  loader: () => import("./views/RedirectHandler"),
  loading: Loading,
});

const Profile = Loadable({
  loader: () => import("./views/Profile"),
  loading: Loading,
});

const HighlightsDemo = Loadable({
  loader: () => import("./views/HighlightsDemo"),
  loading: Loading,
});

const EmailVerification = Loadable({
  loader: () => import("./views/EmailVerification"),
  loading: Loading,
});

const ListSubscriberNotInApp = Loadable({
  loader: () => import("./views/ListSubscribersNotInApp"),
  loading: Loading,
});

const TwitterThreadTweet = Loadable({
  loader: () => import("./views/TwitterThreadTweet"),
  loading: Loading,
});

const RoamExport = Loadable({
  loader: () => import("./views/RoamExport"),
  loading: Loading,
});

const Issues = Loadable({
  loader: () => import("./components/Issues/Issues.js"),
  loading: Loading,
});

const Issue = Loadable({
  loader: () => import("./components/Issue/Issue.js"),
  loading: Loading,
});

const Alert = Loadable({
  loader: () => import("./utils/Alert.js"),
  loading: Loading,
});

//TODO:Create a new CRA and checkout the authentication middleware
// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: "/", name: "Home", component: DefaultLayout, exact: true },
  { path: "/dashboard", name: "Dashboard", component: Dashboard },
  {
    path: "/questionnaire",
    name: "Questionnaire",
    component: Questionnaire,
  },
  { path: "/register", name: "Register", component: Register },
  {
    path: "/learning-path/:id",
    name: "Learning Path",
    component: LearningPath,
  },
  { path: "/learning-paths", name: "Learning Paths", component: LearningPaths },
  {
    path: "/learning-pointers/:id",
    name: "Learning Pointer",
    component: LearningPointer,
  },
  {
    path: "/learning-pointers-display/:path/:id",
    name: "Learning Pointer Display",
    component: LearningPointerDisplay,
  },
  { path: "/logout", name: "Logout", component: Logout },
  { path: "/organization", name: "Organization", component: Organization },
  {
    path: "/create/learning-path",
    name: "Create Learning Path",
    component: createLearningPath,
  },
  { path: "/verify-user/:token", name: "Verify User", component: VerifyUser },
  {
    path: "/highlights/public/:userId/highlight/:highlightId",
    name: "Highlight",
    component: Highlights,
  },
  { path: "/highlights/public/:userId", name: "User", component: Highlights },
  { path: "/highlights/public", name: "Public", component: Highlights },
  { path: "/highlights", name: "Highlights", component: Highlights },
  { path: "/highlight/:id", name: "Highlight", component: Highlight },
  { path: "/profile", name: "Profile", component: Profile },
  {
    path: "/highlights-demo",
    name: "Highlights Demo",
    component: HighlightsDemo,
  },
  {
    path: "/email-verification/:token",
    name: "Email Verification",
    component: EmailVerification,
  },
  {
    path: "/list-subscribers-not-in-app",
    name: "List Subscriber Not in App",
    component: ListSubscriberNotInApp,
  },
  {
    path: "/redirect-handler",
    name: "Redirect Handler",
    component: RedirectHandler,
  },
  {
    path: "/tweet/url/highlight",
    name: "Tweet Url Highlight",
    component: TwitterThreadTweet,
  },
  {
    path: "/roam/url/highlight",
    name: "Export highlights to Roam",
    component: RoamExport,
  },
  { path: "/feedback/issues/:id", name: "issue", component: Issue },
  { path: "/feedback/issues", name: "issues", component: Issues },
];

export default routes;
