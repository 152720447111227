import { combineReducers } from "redux";
import learningPathReducer from "./learningPathReducer";
import authReducer from "./authReducer";
import orgReducer from "./orgReducer";
import searchReducer from "./searchReducer";
import highlightReducer from "./highlightsReducer";
import issue from "./issue";
import alert from "./alert";

export default combineReducers({
  learningPathData: learningPathReducer,
  authData: authReducer,
  orgReducer: orgReducer,
  searchReducer: searchReducer,
  highlightReducer: highlightReducer,
  issue,
  alert,
});
