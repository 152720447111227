import { act } from "react-test-renderer";
import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  token: localStorage.getItem("lpWebToken"),
  error: null,
  loading: false,
  first_name: localStorage.getItem("first_name"),
  last_name: localStorage.getItem("last_name"),
  user_id: localStorage.getItem("user_id"),
  email: localStorage.getItem("email"),
  user_obj: JSON.parse(localStorage.getItem("user_obj")),
  maitre_referral_code: localStorage.getItem("maitre_referral_code"),
};

const authStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.idToken,
    error: null,
    loading: false,
  });
};

const fetchUserDetailSuccess = (state, action) => {
  return updateObject(state, {
    first_name: action.first_name,
    last_name: action.last_name,
    user_id: action.user_id,
    user_obj: action.user_obj,
    email: action.email,
    isUserLoggedIn: true,
    maitre_referral_code: action.maitre_referral_code,
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    token: null,
    first_name: null,
    last_name: null,
    user_id: null,
    user_obj: null,
    email: null,
    error: action.error,
    loading: false,
  });
};

const authLogout = (state, action) => {
  return updateObject(state, { token: null, first_name: null, last_name: null, user_id: null, user_obj: null });
};

const fetchUserProfileSuccess = (state, action) => {
  return updateObject(state, {
    user_profile: action.user_profile,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.FETCH_USER_DETAIL_SUCCESS:
      return fetchUserDetailSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT_SUCCESS:
      return authLogout(state, action);
    case actionTypes.FETCH_USER_PROFILE_SUCCESS:
      return fetchUserProfileSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
