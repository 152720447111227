import React, { Component } from "react";
import { DropdownMenu, DropdownItem, DropdownToggle, Nav, NavItem, NavLink } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { AppAsideToggler, AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from "@coreui/react";

import logo from "../../assets/img/brand/Learning-Paths-Logo-Wide.png";
import ColorsCode from "../../utils/colors_code";
import { ucfirst } from "../../utils/highlights_utill";

// import sygnet from '../../assets/img/brand/sygnet.svg'
// import avatar from '../../assets/img/avatars/6.jpg'

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
  getColorCode = () => {
    return ColorsCode[Math.floor(Math.random() * ColorsCode.length)];
  };

  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        {this.props.pathname.match("learning-pointers-display") ? (
          <AppSidebarToggler className="d-lg-none" display="md" mobile />
        ) : null}
        {this.props.pathname.match("learning-pointers-display") ? (
          <AppSidebarToggler className="d-md-down-none" display="lg" />
        ) : null}
        <AppNavbarBrand href="#/" full={{ src: logo, height: 40, alt: "Learning Paths Logo" }} />
        <Nav className="d-md-down-none" navbar>
          {/* <NavItem className="px-3"><NavLink href="#/create/learning-path">Create Learning Path</NavLink></NavItem> */}
          <NavItem className="px-3">
            {window.location.href.indexOf("#/register") < 0 ? (
              !this.props.token ? (
                <NavLink href="#/register">Login</NavLink>
              ) : (
                <span>
                  <span>
                    {this.props.first_name ? ucfirst(this.props.first_name) : ""}
                    {this.props.last_name ? " " + ucfirst(this.props.last_name) : ""}
                  </span>
                  <span>
                    {this.props.first_name === undefined || this.props.first_name === null ? this.props.email : null}
                  </span>
                </span>
              )
            ) : null}
          </NavItem>
          <AppHeaderDropdown>
            <DropdownToggle nav>
              <div>
                {this.props.token ? (
                  <span
                    className="lp-user-avatar"
                    style={{ background: this.getColorCode() }}
                    alt={this.props.first_name}
                  >
                    <span className="lp-user-avater-text">
                      {this.props.first_name
                        ? this.props.first_name.charAt(0).toUpperCase()
                        : this.props.email
                        ? this.props.email.charAt(0).toUpperCase()
                        : null}
                    </span>
                  </span>
                ) : null}
              </div>
            </DropdownToggle>
            {this.props.token ? (
              <DropdownMenu right style={{ right: "auto", height: "5  0px" }}>
                {this.props.token ? (
                  <div>
                    <DropdownItem>
                      <NavLink href="#/profile">{this.props.email}</NavLink>
                    </DropdownItem>
                    <DropdownItem>
                      <NavLink href="#/profile">Profile</NavLink>
                    </DropdownItem>
                    <DropdownItem>
                      <NavLink href="#/highlights">My Highlights</NavLink>
                    </DropdownItem>
                    <DropdownItem>
                      <NavLink href={`#/highlights/public/${this.props.user_id}`}>My Public Highlights</NavLink>
                    </DropdownItem>
                    <DropdownItem>
                      <NavLink href="#/highlights/public">All Public Highlights</NavLink>
                    </DropdownItem>
                    <DropdownItem>
                      <NavLink href="#/logout">Logout</NavLink>
                    </DropdownItem>
                  </div>
                ) : (
                  <DropdownItem>
                    <NavLink href="#/logout">Login</NavLink>
                  </DropdownItem>
                )}
              </DropdownMenu>
            ) : null}
          </AppHeaderDropdown>
        </Nav>
        <Nav className="d-lg-none" navbar>
          <AppHeaderDropdown>
            <DropdownToggle nav>
              {
                <div>
                  <span
                    className="lp-user-avatar"
                    style={{ background: this.getColorCode() }}
                    alt={this.props.first_name}
                  >
                    <span className="lp-user-avater-text">
                      {this.props.first_name
                        ? this.props.first_name.charAt(0).toUpperCase()
                        : this.props.email
                        ? this.props.email.charAt(0).toUpperCase()
                        : null}
                    </span>
                  </span>
                </div>
              }
            </DropdownToggle>
            <DropdownMenu right style={{ right: "auto", height: "5  0px" }}>
              {/* <DropdownItem><NavLink href="#/create/learning-path">Create Learning Path</NavLink></DropdownItem> */}
              {this.props.token ? (
                <div>
                  <DropdownItem>
                    <NavLink href="#/profile">{this.props.email}</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="#/profile">Profile</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="#/highlights">Highlights</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="#/highlights">My Highlights</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href={`#/highlights/public/${this.props.user_id}`}>My Public Highlights</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="#/highlights/public">All Public Highlights</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="#/logout">Logout</NavLink>
                  </DropdownItem>
                </div>
              ) : (
                <DropdownItem>
                  <NavLink href="#/register">Login</NavLink>
                </DropdownItem>
              )}
            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>
        {/* <AppAsideToggler className="d-md-down-none" /> */}
        {/*<AppAsideToggler className="d-lg-none" mobile />*/}
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  return {
    token: state.authData.token,
    first_name: state.authData.first_name,
    last_name: state.authData.last_name,
    email: state.authData.email,
    user_id: state.authData.user_id,
  };
};

export default connect(mapStateToProps)(DefaultHeader);
