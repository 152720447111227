import prod_config from "../env_configs/prod_config.json";
import staging_config from "../env_configs/staging_config.json";
import local_config from "./config.json";

var config = local_config;
if (process.env && process.env.REACT_APP_BUILD_ENV) {
  switch (process.env.REACT_APP_BUILD_ENV) {
    case "staging":
      config = staging_config;
      break;
    case "production":
      config = prod_config;
      break;
    default:
      config = local_config;
  }
}

export default config;
