import React from "react";
import "./Throbber.css";
import "spinkit/css/spinkit.css";

const BounceLoader = (props) => {
  return (
    <div className="sk-folding-cube">
      <div className="sk-cube1 sk-cube"></div>
      <div className="sk-cube2 sk-cube"></div>
      <div className="sk-cube4 sk-cube"></div>
      <div className="sk-cube3 sk-cube"></div>
    </div>
  );
};

export default BounceLoader;
