import ReactGA from "react-ga";
import config from "../config/config";

export function initializeReactGA() {
  if (config.hasOwnProperty("googleGAID") && config.frontend_url === "https://alpha.app.learningpaths.io/") {
    ReactGA.initialize(config.googleGAID);
  }

  // // for local development
  //  ReactGA.initialize('UA-138782116-1', {
  //    'cookieDomain': 'auto',
  // //    'debug': true
  //   });
}

export function loadPageViewReactGA(pathname) {
  //  ReactGA.set({ userEmail: this.props.userEmail });
  ReactGA.pageview(pathname);
}

export function searchEventReactGA() {
  ReactGA.event({
    category: "Search",
    action: "seach clicked",
    label: "search clicked",
  });
}

export function highlightsPopUpModalReactGA(highlight) {
  ReactGA.event({
    category: highlight.score ? "click Highlight pop up modal from search" : "Highlight pop up modal from DB",
    action: "pop up modal clicked",
  });
}

export function sortButtonReactGA(value) {
  ReactGA.event({
    category: value === "Date" ? "Sort Button Data by date" : "Sort Button Data by Relevance",
    action: value === "Date" ? "Sort Button Data by date" : "Sort Button Data by Relevance",
    label: value === "Date" ? "Sort Button Data by date" : "Sort Button Data by Relevance",
  });
}

export function advancedSearchEventReactGA() {
  ReactGA.event({
    category: "Advanced Search",
    action: "Advanced seach clicked",
    label: "Advanced search",
  });
}

export function sendOutboundEventReactGa(data) {
  ReactGA.outboundLink(
    {
      label: data.hasOwnProperty("score")
        ? "clicked on dashboard image from search"
        : "clicked on dashboard image from DB",
    },
    function () {
      // console.log('redirect here');
    }
  );
}

export function highlightsPopUpModalImageReactGA(data) {
  ReactGA.outboundLink(
    {
      label: data.hasOwnProperty("score")
        ? "clicked on Highlights pop modal image from search"
        : "clicked on Highlights pop modal image",
    },
    function () {
      // console.log('redirect here');
    }
  );
}
