import * as actionTypes from "./actionTypes";
import axios from "../../config/axios";

export const createOrganizationSuccess = (organizationData) => {
  return {
    type: actionTypes.CREATE_ORGANIZATION_SUCCESS,
    organizationData: organizationData,
  };
};

export const createOrganizationFail = (error) => {
  return {
    type: actionTypes.CREATE_ORGANIZATION_FAIL,
    error: error,
  };
};

export const createOrganizationStart = () => {
  return {
    type: actionTypes.CREATE_ORGANIZATION_START,
  };
};

export const submitOrganizationData = (data) => {
  return (dispatch) => {
    dispatch(createOrganizationStart());
    axios
      .post(`/orgs`, data)
      .then((res) => {
        dispatch(createOrganizationSuccess(res.data));
      })
      .catch((err) => {
        dispatch(createOrganizationFail(err));
      });
  };
};
