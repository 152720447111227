import React, { Component } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
// Styles
// CoreUI Icons Set
//import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set
//import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import "font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
import "simple-line-icons/css/simple-line-icons.css";
// Import Main styles for this application
import "./scss/style.css";

// Containers
import { DefaultLayout, Questionnaire } from "./containers";
import * as Sentry from "@sentry/browser";
import config from "./config/config";
import { initializeReactGA } from "./utils/reactGA";

// import { renderRoutes } from 'react-router-config';

class App extends Component {
  sentryInitialization = () => {
    if (config && config.sentry && config.sentry.dsn && config.lpEnv && config.lpEnv !== "local") {
      Sentry.init({
        dsn: config.sentry.dsn,
        environment: config.lpEnv,
      });
    }
  };

  componentWillMount = () => {
    if (config && config.sentry && config.sentry.dsn) {
      this.sentryInitialization();
    }

    initializeReactGA();
  };

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/" name="Home" component={DefaultLayout} />
        </Switch>
      </Router>
    );
  }
}

export default App;
