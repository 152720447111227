import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import * as actions from "../../store/actions";
import { Nav, NavItem, Container, NavLink } from "reactstrap";
import Nestable from "react-nestable";
import { connect } from "react-redux";
import {
  AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
} from "@coreui/react";
// routes config
import routes from "../../routes";
import DefaultAside from "./DefaultAside";
import DefaultFooter from "./DefaultFooter";
import DefaultHeader from "./DefaultHeader";
import ErrorBoundary from "../../hoc/ErrorBoundary/ErrorBoundary";
import { loadPageViewReactGA } from "../../utils/reactGA";

class DefaultLayout extends Component {
  renderItem = (item) => {
    return (
      <NavItem tag="div">
        <NavLink
          href={`#/learning-pointers-display/${this.props.location.pathname.split("/")[2]}/${
            item.item.learningPointerId
          }`}
        >
          {item.item.content}
        </NavLink>
      </NavItem>
    );
  };

  changePointer = (pathId, pointerId) => {
    this.props.history.push(`/learning-pointers-display/${pathId}/${pointerId}`);
  };

  render() {
    loadPageViewReactGA(this.props.location.pathname);
    if (this.props.location.pathname.match("learning-pointers-display")) {
      document.querySelector("body").classList.add("sidebar-lg-show");
    } else {
      document.querySelector("body").classList.remove("sidebar-lg-show");
    }
    return (
      <ErrorBoundary>
        <div className="app">
          <AppHeader fixed>
            <DefaultHeader pathname={this.props.location.pathname} />
          </AppHeader>
          <div className="app-body">
            {this.props.location.pathname.match("learning-pointers-display") ? (
              <AppSidebar fixed display="lg">
                <AppSidebarHeader />
                <AppSidebarForm />
                <Nav>
                  <Nestable items={this.props.path ? this.props.path : null} renderItem={this.renderItem} />
                </Nav>
                <AppSidebarFooter />
                <AppSidebarMinimizer />
              </AppSidebar>
            ) : null}
            <main className="main">
              <AppBreadcrumb appRoutes={routes} />
              <Container fluid>
                <Switch>
                  {routes.map((route, idx) => {
                    return route.component ? (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={(props) => <route.component {...props} />}
                      />
                    ) : null;
                  })}
                  <Redirect from="/" to="/redirect-handler" />
                </Switch>
              </Container>
            </main>
            <AppAside fixed hidden>
              <DefaultAside />
            </AppAside>
          </div>
          <AppFooter>
            <DefaultFooter />
          </AppFooter>
        </div>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.authData.token,
    path: state.learningPathData.learningPath,
    userEmail: state.authData.email,
  };
};

//TODO: call /me api only once
//TODO: check how to write TODOs in Visual Code
const mapDispatchToProps = (dispatch) => {
  return {
    checkAuth: (token) => dispatch(actions.fetchUserDetail(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
