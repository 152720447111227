import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  learningPath: JSON.parse(localStorage.getItem("learningPathData")),
  loading: false,
  error: false,
};

const fetchLearningPathFail = (state, action) => {
  return updateObject(state, { loading: false, error: true });
};

const fetchLearningPathStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchLearningPathSuccess = (state, action) => {
  return updateObject(state, {
    learningPath: action.learningPathData,
    loading: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_LEARNING_PATH_DATA_START:
      return fetchLearningPathStart(state, action);
    case actionTypes.FETCH_LEARNING_PATH_DATA_FAIL:
      return fetchLearningPathFail(state, action);
    case actionTypes.FETCH_LEARNING_PATH_DATA_SUCCESS:
      return fetchLearningPathSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
